var RBETouchHandler = {};

RBETouchHandler.touch = false;
RBETouchHandler.isLongTouch = null;
RBETouchHandler.touchCoords = null;
RBETouchHandler.longTouchCoords = null;
RBETouchHandler.click = false;
RBETouchHandler.isLongClick = null;
RBETouchHandler.clickCoords = null;
RBETouchHandler.longClickCoords = null;
RBETouchHandler.event = null;
RBETouchHandler.isMouse = false;

/**
 * 
 */
RBETouchHandler.initializeEvents = function() {
	/**
	 * This function returns the x and y co-ordinates of touch and mouse click events 
	 * @param e
	 * @param c
	 * @returns
	 */
	function getCoord(e, c) {
	    var org = e.originalEvent,
	        ct = e.changedTouches;
	    return ct || (org && org.changedTouches) ? (org ? org.changedTouches[0]['page' + c] : ct[0]['page' + c]) : e['page' + c];

	}

	/**
	 * This function detects the touch start event
	 */
	$('body').on('touchstart', function(event) {
		RBETouchHandler.touch = true;
		RBETouchHandler.touchCoords = { x:getCoord(event,"X"), y:getCoord(event,"Y") };
		RBETouchHandler.longTouchCoords = RBETouchHandler.touchCoords;
		RBETouchHandler.event = "Touch";
		RBETouchHandler.isLongTouch = setTimeout(RBETouchHandler.longTouchHandler, 2000); 
		RBETouchHandler.displayEvent();
	});

	/**
	 * This function detects the touch move event
	 */
	$('body').on('touchmove', function(event) {
		if(RBETouchHandler.touch) {
			var direction = "";
			var swipeDir = "";
			if(RBETouchHandler.touchCoords.x > getCoord(event,"X")) {
				direction +="left";
				swipeDir = "Left";
			} else {
				direction +=" Right";
				swipeDir = " Right";
			}
			
			if(RBETouchHandler.touchCoords.y > getCoord(event,"Y")) {
				direction +=" and Up";
			} else {
				direction +="Down";
			}
			
			RBETouchHandler.touchCoords = { x:getCoord(event,"X"), y:getCoord(event,"Y") };
			RBETouchHandler.event = "Touch " + swipeDir + " Swipe";
			
			if(Math.abs(RBETouchHandler.longTouchCoords.x - RBETouchHandler.touchCoords.x) > 25
						|| Math.abs(RBETouchHandler.longTouchCoords.y - RBETouchHandler.touchCoords.y) > 25) {
				clearTimeout(RBETouchHandler.isLongTouch);
			}
			RBETouchHandler.displayEvent();
		}
		
	});

	/**
	 * This function detects the touch end event
	 */
	$('body').on('touchend', function(event) {
		RBETouchHandler.touch = false;
		RBETouchHandler.event = "Touch Stopped";	
		//stops short touches from firing the event
	    if (RBETouchHandler.isLongTouch) clearTimeout(RBETouchHandler.isLongTouch); // clearTimeout, not clearTimeout.
		RBETouchHandler.displayEvent();
	});

	/**
	 * This function detects the touch start event
	 */
	$('body').on('mousedown', function(event) {
		if(!RBETouchHandler.touch) {
			RBETouchHandler.click = true;
			RBETouchHandler.clickCoords = { x:getCoord(event,"X"), y:getCoord(event,"Y") };
			RBETouchHandler.event = "Mouse_Click";
			RBETouchHandler.isLongClick = setTimeout(RBETouchHandler.longClickHandler, 2000);
			RBETouchHandler.longClickCoords = RBETouchHandler.clickCoords;
			RBETouchHandler.displayEvent();	
		}
	});

	/**
	 * This function detects the touch move event
	 */
	$('body').on('mousemove', function(event) {
		if(!RBETouchHandler.touch && RBETouchHandler.click) {
			var direction = "";
			var swipeDir = "";
			if(RBETouchHandler.clickCoords.x > getCoord(event,"X")) {
				direction +="left";
				swipeDir = "Left";
			} else {
				direction +="right";
				swipeDir = "Right";
			}
			
			if(RBETouchHandler.clickCoords.y > getCoord(event,"Y")) {
				direction +=" and Up";
			} else {
				direction +=" Down";
			}
			RBETouchHandler.clickCoords = { x:getCoord(event,"X"), y:getCoord(event,"Y") };
			RBETouchHandler.event = "Mouse " + swipeDir + " Swipe";
			
			if(Math.abs(RBETouchHandler.longClickCoords.x - RBETouchHandler.clickCoords.x) > 25
					|| Math.abs(RBETouchHandler.longClickCoords.y - RBETouchHandler.clickCoords.y) > 25) {
				clearTimeout(RBETouchHandler.isLongClick);
			}
			RBETouchHandler.displayEvent();
		}
	});

	/**
	 * This function detects the touch end event
	 */
	$('body').on('mouseup', function(event) {
		if(!RBETouchHandler.touch) {
			RBETouchHandler.click = false;
			RBETouchHandler.event = "Mouse Released";
			//stops short touches from firing the event
		    if (RBETouchHandler.isLongClick) clearTimeout(RBETouchHandler.isLongClick); // clearTimeout, not cleartimeout..
			RBETouchHandler.displayEvent();	
		}
	});
	
	/**
	 * This function detects the touch end event
	 */
	$('body').mouseover(function(event) {
		RBETouchHandler.isMouse = true;
		isMouseSupoprt();
	});
	
};

/**
 * This function returns true if device is a touch device
 */
RBETouchHandler.isTouchDevice = function(){
	if('ontouchstart' in document.documentElement) {
		return true;
	}
	return false;
};

/**
 * This function returns true if device is a touch device
 */
RBETouchHandler.isKeyboard = function(){
	if('onkeydown' in document.documentElement) {
		return true;
	}
	return false;
};

/**
 * This function returns true if device is a touch device
 */
RBETouchHandler.isMouseAvailable = function(){
	return RBETouchHandler.isMouse;
};

/**
 * This functions returns true if the device performed a touch event otherwise false
 */
RBETouchHandler.isTouchEvent = function() {
	if(RBETouchHandler.event == "Touch") {
		return true;
	}
	return false; 
};

/**
 * 
 */
RBETouchHandler.longTouchHandler = function() {
	RBETouchHandler.event = "Long Touch and Hold";
	RBETouchHandler.displayEvent();
};

/**
 * 
 */
RBETouchHandler.longClickHandler = function() {
	RBETouchHandler.event = "Long Click and Hold";
	RBETouchHandler.displayEvent();
};

/**
 * 
 */
RBETouchHandler.displayEvent = function() {
	$('#events').html(RBETouchHandler.event);
};

/**
 * Following handles the swipe event for element passed as parameter
 * @param element
 * @param handler
 */
RBETouchHandler.registerSwipeEvent = function(element, handler) {

    $(element).on('touchstart mousedown', function(event) {
	RBETouchHandler.touchCoords = {
	    x : getCoord(event, "X"),
	    y : getCoord(event, "Y")
	};
    });

    $(element).on('touchend mouseup', function(event) {
	RBETouchHandler.touchCoords = null;
    });

    $(element).on('touchmove mousemove', function(event) {
	if (RBETouchHandler.touchCoords) {
	    if (Math.abs(RBETouchHandler.touchCoords.x - getCoord(event, "X")) > 17
		    || Math.abs(RBETouchHandler.touchCoords.y - getCoord(event, "Y")) > 17) {
		if (RBETouchHandler.touchCoords.x > getCoord(event, "X")) {
		    handler('left');
		} else {
		    handler('right');
		}
	    }
	}
    });
};

/**
 * Following handles the long tap event for element passed as parameter
 * @param element
 * @param handler
 */
RBETouchHandler.registerLongTapEvent = function(element, handler, clearPreviousEvents) {

	var currentEvent = null;
	var isLongTapDetected = false;
	if (clearPreviousEvents) {
		$(element).off("touchstart mousedown", "**");
		$(element).off("touchend mouseup", "**");
		$(element).off("touchmove mousemove", "**");
	}

	$(element).on('touchstart mousedown', function(event) {
		RBETouchHandler.touchCoords = {
			x : getCoord(event, "X"),
			y : getCoord(event, "Y")
		};
		currentEvent = event;
		RBETouchHandler.isLongClick = setTimeout(handleLongTap, 1500);

		if (clearPreviousEvents) {
			event.preventDefault();
			event.stopPropagation();
		}
	});

	$(element).on('touchend mouseup', function(event) {
		RBETouchHandler.touchCoords = null;
		currentEvent = null;
		if (isLongTapDetected) {
			event.stopPropagation();
			event.preventDefault();
		}
		if (RBETouchHandler.isLongClick)
			clearTimeout(RBETouchHandler.isLongClick);
		if (clearPreviousEvents) {
			event.preventDefault();
			event.stopPropagation();
		}
	});

	$(element).on('touchmove mousemove',function(event) {
		if (RBETouchHandler.touchCoords) {
			if (Math.abs(RBETouchHandler.touchCoords.x
					- getCoord(event, "X")) > 20
					|| Math.abs(RBETouchHandler.touchCoords.y
							- getCoord(event, "Y")) > 20) {
				currentEvent = null;
				if (RBETouchHandler.isLongClick)
					clearTimeout(RBETouchHandler.isLongClick);
			}
		}
	});

	$(element).on('click', function(event) {
		if (isLongTapDetected) {
			event.stopPropagation();
			event.preventDefault();
			event.cancelBubble = true;
			if (event.stopImmediatePropagation)
				event.stopImmediatePropagation();
		}
		isLongTapDetected = false;
	});

	function handleLongTap() {
		if (currentEvent) {
			isLongTapDetected = true;
			currentEvent.stopPropagation();
			currentEvent.preventDefault();

			handler(currentEvent);
		}
	}
};

function getCoord(e, c) {
    var org = e.originalEvent,
        ct = e.changedTouches;
    return ct || (org && org.changedTouches) ? (org ? org.changedTouches[0]['page' + c] : ct[0]['page' + c]) : e['page' + c];

}

module.exports = RBETouchHandler;